import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Incident } from "../incident";
import { UsersService } from "../../account-management/users/users.service";
import { UserPermissions } from "src/app/models/shared";
import { IncidentsService } from "../incidents.service";

@Component({
    selector: "app-incident-notes-dialog",
    templateUrl: "./incident-dialog.component.html"
})
export class IncidentDialogComponent implements OnInit, OnDestroy {
    incident: Incident;
    private routeSubscription: Subscription;

    userPermissions: UserPermissions;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private userService: UsersService,
        private is: IncidentsService
    ) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnInit(): void {
        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    canEditIncident(incident: Incident) {
        incident; // TODO: actually use this once we add tags
        return (
            this.userPermissions.is_admin ||
            this.userPermissions.is_objects_manager ||
            this.userPermissions.is_incident_manager ||
            this.userPermissions.is_zixi_support_write ||
            this.userPermissions.is_zixi_admin
        );
    }
}
