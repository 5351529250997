<!--<div ngb-modal-draggable [ngbModalDraggableHandle]="draggableHandle">
    <div #draggableHandle class="modal-header">
        <h3 class="modal-title">{{ "NOTES" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>-->
<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "NOTES" | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form id="form" #form="ngForm">
        <div class="modal-body">
            <zx-advanced-notes [model]="incident" [type]="'incidents'" [id]="incident.id" [canEdit]="canEditIncident(incident)"></zx-advanced-notes>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="activeModal.close()">
                {{ "CLOSE" | translate }}
            </button>
        </div>
    </form>
</div>
